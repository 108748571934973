import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, QueryList } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { combineLatest, merge, of, Subject } from 'rxjs';
import { shareReplay, map, startWith, filter, takeUntil, withLatestFrom, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { PaymentIntentTypes, orderFees } from '@app/base/invoices-base';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { currencyMap } from '../settings';
import { TranslateService } from '@ngx-translate/core';
import { clientFeeLiabilities, getActivePayment } from '@app/base/invoices-base/invoices-base.selector';
import { PaymentKinds } from '@app/base/payments-base/payments-base.constant';
import { PaymentIntentRequest, ActionTypes, ConfirmPaymentLocalSuccess, ConfirmPaymentFail } from '@app/base/invoices-base/invoices-base.action';
import { DialogKey } from './bulk-payment-fees-dialog.constant';
import { StripePaymentStatuses } from '@app/base/invoices-base/invoices-base.constant';
import { ErrorTranslationService } from 'app/services';
import { RemoveError } from '@zerops/fe/ngrx';
var BulkPaymentFeesDialogContainer = /** @class */ (function (_super) {
    tslib_1.__extends(BulkPaymentFeesDialogContainer, _super);
    function BulkPaymentFeesDialogContainer(_store, _translate, _errorTranslation, _stripeService) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._translate = _translate;
        _this._errorTranslation = _errorTranslation;
        _this._stripeService = _stripeService;
        _this.paymentKinds = PaymentKinds;
        _this.dialogKey = DialogKey;
        _this.isCardEntered = false;
        _this.paymentIntentRequestKey = ActionTypes.PaymentIntentRequest;
        _this.confirmPaymentRequestKey = ActionTypes.ConfirmPaymentRequest;
        _this.paymentIntentFailKey = ActionTypes.PaymentIntentFail;
        _this.confirmPaymentFailKey = ActionTypes.ConfirmPaymentFail;
        _this.cardOptions = {
            hidePostalCode: true,
            style: {
                base: {
                    iconColor: '#0077CC',
                    color: '#1A1A1A',
                    lineHeight: '60px',
                    fontWeight: 300,
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSize: '18px',
                    '::placeholder': {
                        color: '#949494'
                    }
                }
            }
        };
        _this.elementsOptions = {
            locale: _this._translate.currentLang
        };
        _this.onTopUp$ = new Subject();
        _this.onClose$ = new Subject();
        _this.currencyMap$ = _this._store.pipe(select(currencyMap), shareReplay());
        _this._dialogState$ = _this._store.pipe(select(getDialogState(_this.dialogKey)));
        _this.open$ = _this._dialogState$.pipe(map(function (_a) {
            var state = _a.state;
            return !!state;
        }));
        _this.paymentKind$ = _this._dialogState$.pipe(map(function (_a) {
            var meta = _a.meta;
            return meta;
        }), map(function (meta) { return !!meta ? meta.paymentKind : undefined; }));
        /**
         * Getting a flag from the open dialog's meta-data to pass it to the processed
         * payment workflow. The reason is to compare it later with the same flag inside
         * the appropriate component instance to eliminate duplicated reactions because
         * there are more instances of the component.
         */
        _this.instanceKind$ = _this._dialogState$.pipe(map(function (_a) {
            var meta = _a.meta;
            return meta;
        }), map(function (meta) { return !!meta ? meta.instanceKind : undefined; }));
        _this.clientFeeLiabilities$ = _this._store.pipe(select(clientFeeLiabilities));
        _this.unpaidFees$ = _this.clientFeeLiabilities$.pipe(map(function (feeLiabilities) { return orderFees(feeLiabilities.unpaidFees, false); }));
        _this.client$ = _this._store.pipe(select(activeUserClient));
        _this.currentLang$ = _this._translate.onLangChange.pipe(startWith(_this._translate.currentLang), map(function () { return _this._translate.currentLang; }));
        _this.qrData$ = combineLatest(_this.client$, _this.clientFeeLiabilities$).pipe(map(function (_a) {
            var client = _a[0], feeLiabilities = _a[1];
            return !!client && !!feeLiabilities
                ? getPaymentQR(
                /**
                 * The iban account number is always defined.
                 */
                feeLiabilities.bankAccount.iban, 
                /**
                 * The total fee amount owed, less credit, if any, but only
                 * if the result is greater than 0, otherwise 0.
                 */
                feeLiabilities.bankTransferSummary.totalDue > 0
                    ? +(feeLiabilities.bankTransferSummary.totalDue).toFixed(2)
                    : 0, 
                /**
                 * The applied currency id is taken directly from a client record.
                 */
                client.currencyId, 
                /**
                 * For a local payment the variable symbol value is used, otherwise null.
                 */
                feeLiabilities.bankAccount.localPayment ? feeLiabilities.bankAccount.variableSymbol : null, 
                /**
                 * The swift bank code is always defined.
                 */
                feeLiabilities.bankAccount.swift, 
                /**
                 * For a foreign payment the payment note value is used, otherwise null.
                 */
                !feeLiabilities.bankAccount.localPayment ? "" + feeLiabilities.bankAccount.paymentNote : null)
                : '';
        }));
        _this._onActivePaymentCard$ = _this._store.pipe(select(getActivePayment), filter(function (activePayment) { return !!activePayment && activePayment.type === PaymentIntentTypes.Fee; }));
        _this._onConfirmPayment$ = _this._onActivePaymentCard$.pipe(filter(function (activePayment) { return !!activePayment.secret; }), filter(function (activePayment) { return activePayment.status === StripePaymentStatuses.IntentRequestSuccess; }), switchMap(function (activePayment) { return _this._stripeService.handleCardPayment(activePayment.secret, _this._stripeCardElement).pipe(switchMap(function (result) {
            if (result.error) {
                return _this._errorTranslation.get$(new HttpErrorResponse({
                    error: {
                        error: {
                            code: result.error.code,
                            message: result.error.message
                        }
                    },
                    status: 400,
                    statusText: result.error.type + ' / ' + result.error.param ? result.error.param : '',
                    url: result.error['doc_url'] ? result.error['doc_url'] : ''
                })).pipe(withLatestFrom(_this._translate.get('error.stripe_general_error', { code: result.error.code })), map(function (_a) {
                    var data = _a[0], generalError = _a[1];
                    if (data.message.split('.')[0] === 'error') {
                        data.message = generalError;
                    }
                    return data;
                }), map(function (data) { return new ConfirmPaymentFail(data); }));
            }
            return of(new ConfirmPaymentLocalSuccess(PaymentIntentTypes.Fee, result.paymentIntent));
        })); }));
        _this.onActivePayment$ = _this._onActivePaymentCard$.pipe(withLatestFrom(_this.instanceKind$), filter(function (_a) {
            var activePayment = _a[0], instanceKind = _a[1];
            return activePayment.instanceKind === instanceKind;
        }), map(function (_a) {
            var activePayment = _a[0];
            if (!activePayment) {
                return false;
            }
            else if (activePayment.status === StripePaymentStatuses.IntentRequestInit ||
                activePayment.status === StripePaymentStatuses.PaymentRequestInit) {
                return true;
            }
            return false;
        }));
        _this._onTopUpAction$ = _this.onTopUp$.pipe(withLatestFrom(_this.instanceKind$, _this.client$, _this.clientFeeLiabilities$), map(function (_a) {
            var _ = _a[0], instanceKind = _a[1], client = _a[2], feeLiabilities = _a[3];
            return new PaymentIntentRequest({
                instanceKind: instanceKind,
                status: StripePaymentStatuses.IntentRequestInit,
                clientId: client.id,
                type: PaymentIntentTypes.Fee,
                amount: +(feeLiabilities.cardPaymentSummary.totalDue).toFixed(2)
            });
        }));
        _this._onCloseAction$ = _this.onClose$.pipe(map(function () { return new RemoveError([_this.paymentIntentFailKey, _this.confirmPaymentFailKey]); }));
        // # Store Dispatcher
        merge(_this._onTopUpAction$, _this._onCloseAction$, _this._onConfirmPayment$).pipe(takeUntil(_this._ngOnDestroy$)).subscribe(_this._store);
        return _this;
    }
    BulkPaymentFeesDialogContainer.prototype._clearContext = function () {
        this.isCardEntered = false;
        this._stripeCardElement = undefined;
    };
    BulkPaymentFeesDialogContainer.prototype.ngOnInit = function () {
        var _this = this;
        /**
         * They are used to clear up local variables when a dialog is closed. It is related
         * to the setting of the missing listening event on the <ngx-stripe> component
         * instance to detect when a client correctly enters all required card values,
         * and it is possible to initialize the payment.
         */
        this._dialogState$.pipe(filter(function (_a) {
            var state = _a.state;
            return !state;
        }), distinctUntilChanged(), takeUntil(this._ngOnDestroy$)).subscribe(function () {
            _this._clearContext();
        });
    };
    BulkPaymentFeesDialogContainer.prototype.ngAfterViewInit = function () {
        var _this = this;
        /**
         * The used version of <ngx-stripe> component contains a problem with the missing
         * 'change' output that allows it to detect when a client correctly enters all
         * required card values and when it is possible to initialize the payment.
         * That's why a new listening event is added on the <ngx-stripe> instance component,
         * which keeps the local variable 'isCardEntered' in sync with the state
         * of the component instance in GUI.
         */
        combineLatest([
            this.paymentKind$,
            this.cards.changes
        ]).pipe(filter(function (_a) {
            var paymentKind = _a[0], _ = _a[1];
            return paymentKind === PaymentKinds.Online;
        }), filter(function (_a) {
            var _ = _a[0], cards = _a[1];
            return cards && cards.first;
        }), takeUntil(this._ngOnDestroy$)).subscribe(function (_a) {
            var _ = _a[0], first = _a[1].first;
            _this._stripeCardElement = first.element;
            _this._stripeCardElement.on('change', function (state) {
                if (state && state.complete) {
                    _this.isCardEntered = state.complete;
                }
                else {
                    _this.isCardEntered = false;
                }
            });
        });
    };
    return BulkPaymentFeesDialogContainer;
}(BaseClass));
export { BulkPaymentFeesDialogContainer };
