<div class="__price">

  <vshcz-price
    mode="bold-symbol"
    [amount]="notApplyCredit ? total : total - credit + cardFee"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="34"
    [symbolSize]=13>
  </vshcz-price>

  <span
    *ngIf="!credit || notApplyCredit"
    class="__price_currency">
    / <span translate="bulkPayment.total"></span>
  </span>

</div>

<!-- credit -->
<div
  *ngIf="credit && credit > 0 && !notApplyCredit"
  class="__credit">

  <span class="__amount-sign">
      ( -
  </span>

  <vshcz-price
    mode="bold"
    class="__amount-price"
    [amount]="credit"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="18"
    [symbolSize]="12">
  </vshcz-price>

  <span
    *ngIf="mode === 'short'"
    class="__amount-text"
    translate="bulkPayment.creditShort">
  </span>

  <span
    *ngIf="mode === 'long'"
    class="__amount-text"
    translate="bulkPayment.creditLong">
  </span>

  <span class="__amount-sign">
    )
  </span>

</div>

<!-- card fee -->
<div
  *ngIf="cardFee && cardFee > 0"
  class="__card-fee">

  <span class="__amount-sign">
      ( +
  </span>

  <vshcz-price
    mode="bold"
    class="__amount-price"
    [amount]="cardFee"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap"
    [priceSize]="18"
    [symbolSize]="12">
  </vshcz-price>

  <span
    *ngIf="mode === 'short'"
    class="__amount-text"
    translate="bulkPayment.cardFeeShort">
  </span>

  <span
    *ngIf="mode === 'long'"
    class="__amount-text"
    translate="bulkPayment.cardFeeLong">
  </span>

  <span class="__amount-sign">
    )
  </span>

</div>