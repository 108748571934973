export enum InvoiceStatuses {
  PaymentOverdue = 'PAYMENT_OVERDUE',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  Paid = 'PAID',
  Canceled = 'CANCELED'
}

export enum FeeTypes {
  Order = 'order',
  ChangeOrder = 'changeOrder'
}

export enum ModuleTokens {
  Name = 'invoicesBase'
}

export enum InvoiceTypes {
  clientServices = 'CLIENT_SERVICES',
  credit = 'CREDIT',
  fee = 'FEE'
}

export enum PaymentIntentTypes {
  Invoice = 'INVOICE',
  Fee = 'FEE',
}

export enum StripePaymentStatuses {
  IntentRequestInit = 'INTENT_REQUEST_INIT',
  IntentRequestSuccess = 'INTENT_REQUEST_SUCCESS',
  IntentRequestFailed = 'INTENT_REQUEST_FAILED',
  PaymentRequestInit = 'PAYMENT_REQUEST_INIT',
  PaymentRequestSuccess = 'PAYMENT_REQUEST_SUCCESS',
  PaymentRequestFailed = 'PAYMENT_REQUEST_FAILED'
}

export const INVOICE_ORDER_PRIORITY_MAP = {
  [InvoiceStatuses.PaymentOverdue]: 2,
  [InvoiceStatuses.WaitingForPayment]: 1,
  [InvoiceStatuses.Paid]: 0,
  [InvoiceStatuses.Canceled]: 0
};

export const FEE_ORDER_PRIORITY_MAP = {
  [FeeTypes.Order]: 1,
  [FeeTypes.ChangeOrder]: 0
};
