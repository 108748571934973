/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bulk-payment-price.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../price/price.component.ngfactory";
import * as i4 from "../price/price.component";
import * as i5 from "@angular/common";
import * as i6 from "./bulk-payment-price.component";
import * as i7 from "@angular/flex-layout";
var styles_BulkPaymentPriceComponent = [i0.styles];
var RenderType_BulkPaymentPriceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulkPaymentPriceComponent, data: {} });
export { RenderType_BulkPaymentPriceComponent as RenderType_BulkPaymentPriceComponent };
function View_BulkPaymentPriceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "__price_currency"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" / "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["translate", "bulkPayment.total"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "bulkPayment.total"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_BulkPaymentPriceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "__amount-text"], ["translate", "bulkPayment.creditShort"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "bulkPayment.creditShort"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulkPaymentPriceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "__amount-text"], ["translate", "bulkPayment.creditLong"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "bulkPayment.creditLong"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulkPaymentPriceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "__credit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "__amount-sign"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ( - "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "vshcz-price", [["class", "__amount-price"], ["mode", "bold"]], null, null, null, i3.View_PriceComponent_0, i3.RenderType_PriceComponent)), i1.ɵdid(4, 49152, null, 0, i4.PriceComponent, [], { currencyMap: [0, "currencyMap"], mode: [1, "mode"], amount: [2, "amount"], currencyId: [3, "currencyId"], priceSize: [4, "priceSize"], symbolSize: [5, "symbolSize"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPaymentPriceComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPaymentPriceComponent_4)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "__amount-sign"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ) "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currencyMap; var currVal_1 = "bold"; var currVal_2 = _co.credit; var currVal_3 = _co.currencyId; var currVal_4 = 18; var currVal_5 = 12; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (_co.mode === "short"); _ck(_v, 6, 0, currVal_6); var currVal_7 = (_co.mode === "long"); _ck(_v, 8, 0, currVal_7); }, null); }
function View_BulkPaymentPriceComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "__amount-text"], ["translate", "bulkPayment.cardFeeShort"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "bulkPayment.cardFeeShort"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulkPaymentPriceComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "__amount-text"], ["translate", "bulkPayment.cardFeeLong"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "bulkPayment.cardFeeLong"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulkPaymentPriceComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "__card-fee"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "__amount-sign"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ( + "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "vshcz-price", [["class", "__amount-price"], ["mode", "bold"]], null, null, null, i3.View_PriceComponent_0, i3.RenderType_PriceComponent)), i1.ɵdid(4, 49152, null, 0, i4.PriceComponent, [], { currencyMap: [0, "currencyMap"], mode: [1, "mode"], amount: [2, "amount"], currencyId: [3, "currencyId"], priceSize: [4, "priceSize"], symbolSize: [5, "symbolSize"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPaymentPriceComponent_6)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPaymentPriceComponent_7)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "__amount-sign"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ) "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currencyMap; var currVal_1 = "bold"; var currVal_2 = _co.cardFee; var currVal_3 = _co.currencyId; var currVal_4 = 18; var currVal_5 = 12; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (_co.mode === "short"); _ck(_v, 6, 0, currVal_6); var currVal_7 = (_co.mode === "long"); _ck(_v, 8, 0, currVal_7); }, null); }
export function View_BulkPaymentPriceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "__price"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vshcz-price", [["mode", "bold-symbol"]], null, null, null, i3.View_PriceComponent_0, i3.RenderType_PriceComponent)), i1.ɵdid(2, 49152, null, 0, i4.PriceComponent, [], { currencyMap: [0, "currencyMap"], mode: [1, "mode"], amount: [2, "amount"], currencyId: [3, "currencyId"], priceSize: [4, "priceSize"], symbolSize: [5, "symbolSize"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPaymentPriceComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPaymentPriceComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkPaymentPriceComponent_5)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currencyMap; var currVal_1 = "bold-symbol"; var currVal_2 = (_co.notApplyCredit ? _co.total : ((_co.total - _co.credit) + _co.cardFee)); var currVal_3 = _co.currencyId; var currVal_4 = 34; var currVal_5 = 13; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (!_co.credit || _co.notApplyCredit); _ck(_v, 4, 0, currVal_6); var currVal_7 = ((_co.credit && (_co.credit > 0)) && !_co.notApplyCredit); _ck(_v, 6, 0, currVal_7); var currVal_8 = (_co.cardFee && (_co.cardFee > 0)); _ck(_v, 8, 0, currVal_8); }, null); }
export function View_BulkPaymentPriceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vshcz-bulk-payment-price", [], null, null, null, View_BulkPaymentPriceComponent_0, RenderType_BulkPaymentPriceComponent)), i1.ɵdid(1, 49152, null, 0, i6.BulkPaymentPriceComponent, [i7.ObservableMedia], null, null)], null, null); }
var BulkPaymentPriceComponentNgFactory = i1.ɵccf("vshcz-bulk-payment-price", i6.BulkPaymentPriceComponent, View_BulkPaymentPriceComponent_Host_0, { notApplyCredit: "notApplyCredit", total: "total", credit: "credit", cardFee: "cardFee", currencyId: "currencyId", mode: "mode", currencyMap: "currencyMap" }, {}, []);
export { BulkPaymentPriceComponentNgFactory as BulkPaymentPriceComponentNgFactory };
