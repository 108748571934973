export enum ModuleTokens {
  Name = 'paymentsBase'
}

export enum PaymentTypes {
  BankTransfer = 'BANK_TRANSFER',
  Internal = 'INTERNAL',
  Credit = 'CREDIT'
}

export enum PaymentKinds {
  Transfer = 'TRANSFER',
  Online = 'ONLINE'
}

export enum PaymentStatuses {
  Ok = 'OK',
  AmountNotMatched = 'AMOUNT_NOT_MATCHED'
}
