<mat-icon class="__item-type-icon __fee-icon">call_received</mat-icon>

<span class="__item_main">
  <vshcz-price
    [priceSize]="priceSize"
    [symbolSize]="symbolSize"
    [amount]="totalDue"
    [currencyId]="currencyId"
    [currencyMap]="currencyMap">
  </vshcz-price>
</span>

<span class="__item_secondary">
  <span># <strong>{{ number }}</strong></span>
</span>

<vshcz-fee-type
  class="__type"
  [type]="type"
  [tooltip]="'feeTypes.tooltips.' + type | translate">
</vshcz-fee-type>