import { OnInit, AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { combineLatest, merge, of, Subject } from 'rxjs';
import {
  shareReplay,
  map,
  startWith,
  filter,
  takeUntil,
  withLatestFrom,
  switchMap,
  distinctUntilChanged
} from 'rxjs/operators';
import { ElementOptions, ElementsOptions, StripeService, StripeCardComponent, Element } from 'ngx-stripe';
import { State } from '@app/models';
import { PaymentIntentTypes, orderFees } from '@app/base/invoices-base';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { currencyMap } from '../settings';
import { TranslateService } from '@ngx-translate/core';
import { clientFeeLiabilities, getActivePayment } from '@app/base/invoices-base/invoices-base.selector';
import { PaymentKinds } from '@app/base/payments-base/payments-base.constant';
import {
  PaymentIntentRequest,
  ActionTypes,
  ConfirmPaymentLocalSuccess,
  ConfirmPaymentFail
} from '@app/base/invoices-base/invoices-base.action';
import { DialogKey } from './bulk-payment-fees-dialog.constant';
import { StripePaymentStatuses } from '@app/base/invoices-base/invoices-base.constant';
import { ErrorTranslationService } from 'app/services';
import { RemoveError } from '@zerops/fe/ngrx';

@Component({
  selector: 'vshcz-bulk-payment-fees-dialog',
  templateUrl: './bulk-payment-fees-dialog.container.html',
  styleUrls: [ './bulk-payment-fees-dialog.container.scss' ],
})
export class BulkPaymentFeesDialogContainer extends BaseClass implements OnInit, AfterViewInit {

  private _stripeCardElement: Element;

  paymentKinds = PaymentKinds;
  dialogKey = DialogKey;
  currencyId: string;
  isCardEntered = false;

  paymentIntentRequestKey = ActionTypes.PaymentIntentRequest;
  confirmPaymentRequestKey = ActionTypes.ConfirmPaymentRequest;
  paymentIntentFailKey = ActionTypes.PaymentIntentFail;
  confirmPaymentFailKey = ActionTypes.ConfirmPaymentFail;

  cardOptions: ElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#0077CC',
        color: '#1A1A1A',
        lineHeight: '60px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#949494'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: this._translate.currentLang as any
  };

  onTopUp$ = new Subject<void>();
  onClose$ = new Subject<void>();

  currencyMap$ = this._store.pipe(
    select(currencyMap),
    shareReplay()
  );

  private _dialogState$ = this._store.pipe(
    select(getDialogState(this.dialogKey))
  );

  open$ = this._dialogState$.pipe(
    map(({ state }) => !!state)
  );

  paymentKind$ = this._dialogState$.pipe(
    map(({ meta }) => meta as {
      instanceKind: 'dashboard' | 'menu' | 'route';
      paymentKind: PaymentKinds;
      paymentIntentType: PaymentIntentTypes;
    }),
    map((meta) => !!meta ? meta.paymentKind : undefined)
  );

  /**
   * Getting a flag from the open dialog's meta-data to pass it to the processed
   * payment workflow. The reason is to compare it later with the same flag inside
   * the appropriate component instance to eliminate duplicated reactions because
   * there are more instances of the component.
   */
  instanceKind$ = this._dialogState$.pipe(
    map(({ meta }) => meta as {
      instanceKind: 'dashboard' | 'menu' | 'route';
      paymentKind: PaymentKinds;
      paymentIntentType: PaymentIntentTypes;
    }),
    map((meta) => !!meta ? meta.instanceKind : undefined)
  );

  clientFeeLiabilities$ = this._store.pipe(
    select(clientFeeLiabilities)
  );

  unpaidFees$ = this.clientFeeLiabilities$.pipe(
    map((feeLiabilities) => orderFees(feeLiabilities.unpaidFees, false))
  );

  client$ = this._store.pipe(
    select(activeUserClient)
  );

  currentLang$ = this._translate.onLangChange.pipe(
    startWith(this._translate.currentLang),
    map(() => this._translate.currentLang)
  );

  qrData$ = combineLatest(
    this.client$,
    this.clientFeeLiabilities$
  ).pipe(
    map(([ client, feeLiabilities ]) => !!client && !!feeLiabilities
      ? getPaymentQR(
        /**
         * The iban account number is always defined.
         */
        feeLiabilities.bankAccount.iban,
        /**
         * The total fee amount owed, less credit, if any, but only
         * if the result is greater than 0, otherwise 0.
         */
        feeLiabilities.bankTransferSummary.totalDue > 0
          ? +(feeLiabilities.bankTransferSummary.totalDue).toFixed(2)
          : 0,
        /**
         * The applied currency id is taken directly from a client record.
         */
        client.currencyId,
        /**
         * For a local payment the variable symbol value is used, otherwise null.
         */
        feeLiabilities.bankAccount.localPayment ? feeLiabilities.bankAccount.variableSymbol : null,
        /**
         * The swift bank code is always defined.
         */
        feeLiabilities.bankAccount.swift,
        /**
         * For a foreign payment the payment note value is used, otherwise null.
         */
        !feeLiabilities.bankAccount.localPayment ? `${feeLiabilities.bankAccount.paymentNote}` : null
      )
      : ''
    )
  );

  @ViewChildren(StripeCardComponent) cards!: QueryList<StripeCardComponent>;

  private _onActivePaymentCard$ = this._store.pipe(
    select(getActivePayment),
    filter((activePayment) => !!activePayment && activePayment.type === PaymentIntentTypes.Fee)
  );

  private _onConfirmPayment$ = this._onActivePaymentCard$.pipe(
    filter((activePayment) => !!activePayment.secret),
    filter((activePayment) => activePayment.status === StripePaymentStatuses.IntentRequestSuccess),
    switchMap((activePayment) => this._stripeService.handleCardPayment(
      activePayment.secret,
      this._stripeCardElement
    ).pipe(
      switchMap((result) => {
        if (result.error) {
          return this._errorTranslation.get$(new HttpErrorResponse({
            error: {
              error: {
                code: result.error.code,
                message: result.error.message
              }
            },
            status: 400,
            statusText: result.error.type + ' / ' + result.error.param ? result.error.param : '',
            url: result.error['doc_url'] ? result.error['doc_url'] : ''
          })).pipe(
            withLatestFrom(this._translate.get('error.stripe_general_error', { code: result.error.code })),
            map(([ data, generalError ]) => {
              if (data.message.split('.')[0] === 'error') {
                data.message = generalError;
              }
              return data;
            }),
            map((data) => new ConfirmPaymentFail(data))
          );
        }
        return of(new ConfirmPaymentLocalSuccess(PaymentIntentTypes.Fee, result.paymentIntent));
      })
    ))
  );

  onActivePayment$ = this._onActivePaymentCard$.pipe(
    withLatestFrom(this.instanceKind$),
    filter(([ activePayment, instanceKind ]) => activePayment.instanceKind === instanceKind),
    map(([ activePayment ]) => {
      if (!activePayment) {
        return false;
      } else if (
        activePayment.status === StripePaymentStatuses.IntentRequestInit ||
        activePayment.status === StripePaymentStatuses.PaymentRequestInit
      ) {
        return true;
      }
      return false;
    })
  );

  private _onTopUpAction$ = this.onTopUp$.pipe(
    withLatestFrom(
      this.instanceKind$,
      this.client$,
      this.clientFeeLiabilities$
    ),
    map(([ _, instanceKind, client, feeLiabilities ]) => new PaymentIntentRequest({
      instanceKind,
      status: StripePaymentStatuses.IntentRequestInit,
      clientId: client.id,
      type: PaymentIntentTypes.Fee,
      amount: +(feeLiabilities.cardPaymentSummary.totalDue).toFixed(2)
    }))
  );

  private _onCloseAction$ = this.onClose$.pipe(
    map(() => new RemoveError([this.paymentIntentFailKey, this.confirmPaymentFailKey]))
  );

  private _clearContext(): void {
    this.isCardEntered = false;
    this._stripeCardElement = undefined;
  }

  constructor(
    private _store: Store<State>,
    private _translate: TranslateService,
    private _errorTranslation: ErrorTranslationService,
    private _stripeService: StripeService
  ) {
    super();

    // # Store Dispatcher
    merge(
      this._onTopUpAction$,
      this._onCloseAction$,
      this._onConfirmPayment$
    ).pipe(
      takeUntil(this._ngOnDestroy$)
    ).subscribe(this._store);
  }

  ngOnInit() {

    /**
     * They are used to clear up local variables when a dialog is closed. It is related
     * to the setting of the missing listening event on the <ngx-stripe> component
     * instance to detect when a client correctly enters all required card values,
     * and it is possible to initialize the payment.
     */
    this._dialogState$.pipe(
      filter(({ state }) => !state),
      distinctUntilChanged(),
      takeUntil(this._ngOnDestroy$)
    ).subscribe(() => {
      this._clearContext();
    });

  }

  ngAfterViewInit() {

    /**
     * The used version of <ngx-stripe> component contains a problem with the missing
     * 'change' output that allows it to detect when a client correctly enters all
     * required card values and when it is possible to initialize the payment.
     * That's why a new listening event is added on the <ngx-stripe> instance component,
     * which keeps the local variable 'isCardEntered' in sync with the state
     * of the component instance in GUI.
     */
    combineLatest([
      this.paymentKind$,
      this.cards.changes
    ]).pipe(
      filter(([ paymentKind, _ ]) => paymentKind === PaymentKinds.Online),
      filter(([ _, cards ]) => cards && cards.first),
      takeUntil(this._ngOnDestroy$)
    ).subscribe(([ _, { first } ]) => {
      this._stripeCardElement = (first as StripeCardComponent).element;
      this._stripeCardElement.on('change', (state) => {
        if (state && state.complete) {
          this.isCardEntered = state.complete;
        } else {
          this.isCardEntered = false;
        }
      });
    });
  }

}
