import { ClientEntityLight } from '@app/base/clients-base';
import { FeeTypes, ModuleTokens } from './invoices-base.constant';
import { PaymentIntentTypes } from '../invoices-base';
import { StripePaymentStatuses } from './invoices-base.constant';

// invoice
export class InvoicesBaseState {
  entities: InvoiceEntityEntities;
  list: string[];
  invoiceLiabilities: ClientInvoiceLiabilities;
  feeLiabilities: ClientFeeLiabilities;
  activePayment: {
    instanceKind: 'dashboard' | 'menu' | 'route';
    status: StripePaymentStatuses;
    type: PaymentIntentTypes;
    amount: number;
    secret?: string;
  };

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: {}
    };

    this.list = undefined;
    this.invoiceLiabilities = undefined;
    this.feeLiabilities = undefined;
    this.activePayment = undefined;
  }
}

export class InvoiceEntityEntities {
  [ModuleTokens.Name]: { [id: string]: InvoiceEntityNormalized; };
}

export class InvoiceEntityNormalizedData {
  entities: InvoiceEntityEntities;
  result: string[];
}

export interface BankAccount {
  localPayment: boolean;
  localAccountNumber: string;
  iban: string;
  swift: string;
  bankName: string;
  variableSymbol: string | null;
  paymentNote: string | null;
}

export class InvoiceEntityLight {
  id: string;
  clientId: string;
  status: string;
  contractorId: string;
  number: number;
  created: string;
  dueDate: string;
  paidDate: string;
  total: number;
  totalCzk: number;
  vatAmount: number;
  vatAmountCzk: number;
  totalDue: number;
  totalVat: number;
  totalVatCzk: number;
  currencyConversionRate: number;
  currencyId: string;
  daysOverdue: number;
  type: string;
  contractorBankAccount?: BankAccount;
}

export interface BankTransferSummary {
  totalLiabilities: number;
  credit: number;
  totalDue: number;
}

export interface CardPaymentSummary {
  totalLiabilities: number;
  credit: number;
  cardPaymentFeePercent: number;
  cardPaymentFeeAmount: number;
  totalDue: number;
}

export interface ClientInvoiceLiabilities {
  processingPayment: boolean;
  bankTransferSummary?: BankTransferSummary;
  bankAccount?: BankAccount;
  unpaidInvoices: Array<InvoiceEntityLight>;
  unpaidInvoiceList?: Array<string>;
  cardPaymentEnabled: boolean;
  cardPaymentSummary?: CardPaymentSummary;
}

export interface FeeLight {
  id: string;
  type: FeeTypes;
  number: number;
  totalDue: number;
}

export interface ClientFeeLiabilities {
  processingPayment: boolean;
  bankTransferSummary?: BankTransferSummary;
  bankAccount?: BankAccount;
  unpaidFees: Array<FeeLight>;
  unpaidFeeList?: Array<string>;
  cardPaymentEnabled: boolean;
  cardPaymentSummary?: CardPaymentSummary;
}

export class InvoiceEntityBase extends InvoiceEntityLight {
  client: ClientEntityLight;
  // TODO: interfaces
  clientAddress: any;
  contractor: ContractorEntityBase;
  contractorAddress: any;
}

export class InvoiceEntityNormalized extends InvoiceEntityBase {
  invoiceItemList: string[];
}

export class InvoiceEntity extends InvoiceEntityBase {
  invoiceItemList: InvoiceItemEntityLight[];
}

// invoice item
export class InvoiceItemEntityLight {
  id: string;
  invoiceId: string;
  clientServiceId: string;
  type: string;
  itemName: string;
  itemIdentification: string;
  currencyId: string;
  amount: number;
  unitPrice: number;
  vatRate: number;
}

export class InvoiceItemEntityBase extends InvoiceItemEntityLight {
  billingPeriodStart: string;
  billingPeriodEnd: string;
  isReverseCharged: boolean;
  invoiceCustomItemId: string;
}

// contractor
export class ContractorEntityLight {
  id: string;
  companyName: string;
  isActive: boolean;
  companyNumber: string;
  vatNumber: string;
}

export class ContractorEntityBase extends ContractorEntityLight {
  created: string;
  lastUpdate: string;
  // TODO: interface
  invoiceAddress: any;
}
