import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, share, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, interval, merge, of } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import { State } from '@app/models';
import { paymentsListEntities } from '@app/base/payments-base';
import { PaymentIntentTypes, invoicesListEntities } from '@app/base/invoices-base';
import { currencyMap } from '@app/common/settings';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
import {
  clientInvoiceLiabilities,
  clientFeeLiabilities,
  paymentBackendProcessing,
  getActivePayment
} from '@app/base/invoices-base/invoices-base.selector';
import { LiabilityFeeRequest, LiabilityInvoiceRequest, ListRequest } from '@app/base/invoices-base/invoices-base.action';
import { SnackService } from '../snack';
import { TranslateService } from '@ngx-translate/core';
import { StripePaymentStatuses } from '@app/base/invoices-base/invoices-base.constant';

@Component({
  selector: 'vshcz-billing-card',
  templateUrl: './billing-card.container.html',
  styleUrls: [ './billing-card.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingCardContainer extends BaseClass {

  private _repeatReloadCount = 6;
  private _repeatTiming = 1000;
  private _reloadListDone = false;

  // # Data
  @Input()
  leftLayout: number | string;

  @Input()
  rightLayout: number | string;

  /**
   * A flag that identifies the appropriate instance of the component is used
   * to compare it to the state of a processed payment in the store.
   */
  @Input()
  instanceKind: 'dashboard' | 'menu' = 'dashboard';

  // -- async
  latestPayments$ = this._store.pipe(
    select(paymentsListEntities),
    map((payments) => orderBy(payments, [ 'created' ], [ 'desc' ]).slice(0, 5))
  );

  latestInvoices$ = this._store.pipe(
    select(invoicesListEntities),
    map(invoices => {
      if (invoices) {
        return invoices.slice(0, 5);
      }
      return invoices;
    })
  );

  clientInvoiceLiabilities$ = this._store.pipe(
    select(clientInvoiceLiabilities)
  );

  clientFeeLiabilities$ = this._store.pipe(
    select(clientFeeLiabilities)
  );

  latestFees$ = this.clientFeeLiabilities$.pipe(
    filter((d) => !!d),
    map(feeLiabilities => feeLiabilities.unpaidFees.slice(0, 5))
  );

  client$ = this._store.pipe(
    select(activeUserClient)
  );

  invoicesQrData$ = combineLatest(
    this.client$,
    this.clientInvoiceLiabilities$,
    this.clientFeeLiabilities$
  ).pipe(
    map(([ client, invoiceLiabilities, feeLiabilities ]) => !!client && !!invoiceLiabilities && !!feeLiabilities
      ? getPaymentQR(
        /**
         * The iban account number is always defined.
         */
        invoiceLiabilities.bankAccount.iban,
        /**
         * If there are both unpaid invoices and unpaid fees, do not count a credit, if it exists,
         * and use only the total invoice amount owed. The reason is to avoid a misunderstanding
         * when the same credit is applied in both places at the same moment.
         * If there are only unpaid invoices then use the total invoice amount owed, less credit,
         * if any, but only if the result is greater than 0, otherwise 0.
         */
        invoiceLiabilities.bankTransferSummary.totalDue > 0 && feeLiabilities.bankTransferSummary.totalDue > 0
          ? +(invoiceLiabilities.bankTransferSummary.totalLiabilities).toFixed(2)
          : invoiceLiabilities.bankTransferSummary.totalDue > 0
            ? +(invoiceLiabilities.bankTransferSummary.totalDue).toFixed(2)
            : 0,
        /**
         * The applied currency id is taken directly from a client record.
         */
        client.currencyId,
        /**
         * For a local payment the variable symbol value is used, otherwise null.
         */
        invoiceLiabilities.bankAccount.localPayment ? invoiceLiabilities.bankAccount.variableSymbol : null,
        /**
         * The swift bank code is always defined.
         */
        invoiceLiabilities.bankAccount.swift,
        /**
         * For a foreign payment the payment note value is used, otherwise null.
         */
        !invoiceLiabilities.bankAccount.localPayment ? `${invoiceLiabilities.bankAccount.paymentNote}` : null
      )
      : ''
    )
  );

  feesQrData$ = combineLatest(
    this.client$,
    this.clientInvoiceLiabilities$,
    this.clientFeeLiabilities$
  ).pipe(
    map(([ client, invoiceLiabilities, feeLiabilities ]) => !!client && !!invoiceLiabilities && !!feeLiabilities
      ? getPaymentQR(
        /**
         * The iban account number is always defined.
         */
        feeLiabilities.bankAccount.iban,
        /**
         * If there are both unpaid invoices and unpaid fees, do not count a credit, if it exists,
         * and use only the total fee amount owed. The reason is to avoid a misunderstanding
         * when the same credit is applied in both places at the same moment.
         * If there are only unpaid fees then use the total fee amount owed, less credit,
         * if any, but only if the result is greater than 0, otherwise 0.
         */
        invoiceLiabilities.bankTransferSummary.totalDue > 0 && feeLiabilities.bankTransferSummary.totalDue > 0
          ? +(feeLiabilities.bankTransferSummary.totalLiabilities).toFixed(2)
          : feeLiabilities.bankTransferSummary.totalDue > 0
            ? +(feeLiabilities.bankTransferSummary.totalDue).toFixed(2)
            : 0,
        /**
         * The applied currency id is taken directly from a client record.
         */
        client.currencyId,
        /**
         * For a local payment the variable symbol value is used, otherwise null.
         */
        feeLiabilities.bankAccount.localPayment ? feeLiabilities.bankAccount.variableSymbol : null,
        /**
         * The swift bank code is always defined.
         */
        feeLiabilities.bankAccount.swift,
        /**
         * For a foreign payment the payment note value is used, otherwise null.
         */
        feeLiabilities.bankAccount.localPayment ? '' : `${feeLiabilities.bankAccount.paymentNote}`
      )
      : ''
    )
  );

  currencyMap$ = this._store.pipe(
    select(currencyMap),
    shareReplay()
  );

  // -- angular
  @Output()
  contentUpdated = new EventEmitter<void>();

  @Output()
  contentClicked = new EventEmitter<void>();

  /**
   * The emitted value decides if an overlay above the invoice detail card shows
   * a message explaining that the payment pairing is processed on the backend.
   */
  onInvoicePaymentBackendProcessing$ = this._store.pipe(
    select(paymentBackendProcessing(this.instanceKind, PaymentIntentTypes.Invoice)),
    distinctUntilChanged()
  );

  /**
   * The emitted value decides if an overlay above the fee detail card shows
   * a message explaining that the payment pairing is processed on the backend.
   */
  onFeePaymentBackendProcessing$ = this._store.pipe(
    select(paymentBackendProcessing(this.instanceKind, PaymentIntentTypes.Fee)),
    distinctUntilChanged()
  );

  /**
   * The following subscription is used to control the workflow of the processed payment.
   * The backend is repeatedly checked to detect when the payment is completed, allowing
   * a client to make another one. The key moment is pairing the payment with existed
   * client liabilities.
   */
  private _onActivePaymentCard$ = this._store.pipe(
    select(getActivePayment),
    filter((activePayment) => !!activePayment && activePayment.instanceKind === this.instanceKind),
    filter((activePayment) => activePayment.status === StripePaymentStatuses.PaymentRequestSuccess),
    map((activePayment) => activePayment.type),
    tap(() => this._reloadListDone = false),
    switchMap((paymentIntentType) => combineLatest(
      of(paymentIntentType),
      interval(this._repeatTiming).pipe(take(this._repeatReloadCount)))
    ),
    withLatestFrom(
      this.clientInvoiceLiabilities$,
      this.clientFeeLiabilities$
    ),
    share()
  );

  /**
   * If the repeated liability requests to the backend lead to the situation when the returned
   * data still shows unpaid invoices/fees, the backend pairing process fails to finish
   * successfully within the defined time. The error message is displayed to clients,
   * asking them to refresh the page manually.
   */
  private _onReloadFailing$ = this._onActivePaymentCard$.pipe(
    filter(([ [ _, count ] ]) => count === this._repeatReloadCount - 1 ? true : false),
    filter(([ [ paymentIntentType ], invoiceLiabilities, feeLiabilities ]) => {
      if (paymentIntentType === PaymentIntentTypes.Invoice) {
        return invoiceLiabilities.unpaidInvoices.length > 0 ? true : false;
      }
      return feeLiabilities.unpaidFees.length > 0 ? true : false;
    }),
    switchMap(() => this._translate.get(['bulkPayment.failPairingPayment', 'common.close']).pipe(
      map((translations) => this._snack.fail$(
        translations['bulkPayment.failPairingPayment'],
        translations['common.close'], 10000, 'center'
      ))
    )),
    takeUntil(this._ngOnDestroy$)
  ).subscribe();

  /**
   * The invoice/fee unpaid liability data returned from the backend API should not contain
   * unpaid items after the payment is actually processed on the backend. If there are any,
   * a request is repeated a limited number of times.
   */
  private _onReloadPaymentLiability$ = this._onActivePaymentCard$.pipe(
    filter(([ [ paymentIntentType ], invoiceLiabilities, feeLiabilities ]) => {
      if (paymentIntentType === PaymentIntentTypes.Invoice) {
        return invoiceLiabilities.unpaidInvoices.length > 0 ? true : false;
      }
      return feeLiabilities.unpaidFees.length > 0 ? true : false;
    }),
    map(([ [ paymentIntentType ] ]) => {
      if (paymentIntentType === PaymentIntentTypes.Invoice) {
        return new LiabilityInvoiceRequest();
      }
      return new LiabilityFeeRequest();
    })
  );

  /**
   * Supposing the returned invoice/fee unpaid data contains no unpaid items,
   * it is necessary to get the list of existing invoices containing the last
   * ones created after pairing the last payment.
   */
  private _onReloadCompletion$ = this._onActivePaymentCard$.pipe(
    filter(([ [ paymentIntentType ], invoiceLiabilities, feeLiabilities ]) => {
      if (paymentIntentType === PaymentIntentTypes.Invoice) {
        return invoiceLiabilities.unpaidInvoices.length > 0
          ? false
          : !this._reloadListDone
            ? true
            : false;
      }
      return feeLiabilities.unpaidFees.length > 0
        ? false
        : !this._reloadListDone
          ? true
          : true;
    }),
    tap(() => this._reloadListDone = true),
    map(([ [ paymentIntentType ] ]) => paymentIntentType),
    share()
  );

  private _onReloadLiabilities$ = this._onReloadCompletion$.pipe(
    map((paymentIntentType) => paymentIntentType === PaymentIntentTypes.Invoice
      ? new LiabilityFeeRequest()
      : new LiabilityInvoiceRequest()
    )
  );

  private _onReloadList$ = this._onReloadCompletion$.pipe(
    switchMap(() => this._translate.get(['bulkPayment.successPairingPayment', 'common.close']).pipe(
      map((translations) => this._snack.success$(translations['bulkPayment.successPairingPayment'], translations['common.close']))
    )),
    map(() => new ListRequest())
  );

  constructor(
    private _store: Store<State>,
    private _snack: SnackService,
    private _translate: TranslateService
  ) {
    super();

    // emit that content changed so menu can resize the pop
    combineLatest(
      this.latestInvoices$,
      this.latestPayments$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(() => this.contentUpdated.emit());

    // # Store Dispatcher
    merge(
      this._onReloadPaymentLiability$,
      this._onReloadLiabilities$,
      this._onReloadList$
    ).pipe(
      takeUntil(this._ngOnDestroy$)
    ).subscribe(this._store);

  }

  _trackBy(index: number) {
    return index;
  }
}
