import { Component, Input } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Currency } from '../settings';
import { HashMap } from 'utils';

@Component({
  selector: 'vshcz-bulk-payment-price',
  templateUrl: './bulk-payment-price.component.html',
  styleUrls: [ './bulk-payment-price.component.scss' ],
})
export class BulkPaymentPriceComponent {

  @Input()
  notApplyCredit = false;

  @Input()
  total = 0;

  @Input()
  credit = 0;

  @Input()
  cardFee = 0;

  @Input()
  currencyId: string;

  @Input()
  mode: 'long' | 'short' = 'long';

  @Input()
  currencyMap: HashMap<Currency>;

  constructor(public media: ObservableMedia) { }
}
