import { version } from './version';

export const environment = {
  production: true,
  envName: 'cm',
  apiUrl: '',
  wsUrl: 'cdn.cm.dev.kube.vsh-labs.cz',
  cloudDnsWsUrl: 'client.cm.dev.kube.vsh-labs.cz',
  googleSiteKey: 'undefined',
  cdnAuthRedirectUrl: 'https://cdn.cm.dev.kube.vsh-labs.cz/authorize',
  cloudMailAuthRedirectUrl: 'https://cloudmail.cm.dev.kube.vsh-labs.cz/authorize',
  release: version,
  enableCDNS: '1',
  enableCDN: '1',
  enableCloudMail: '1',
  forceDevWsProtocol: 'wss',
  mojeIdConnectRedirectUrl: 'https://client.cm.dev.kube.vsh-labs.cz/external-auth/mojeid/connect',
  mojeIdLoginRedirectUrl: 'https://client.cm.dev.kube.vsh-labs.cz/external-auth/mojeid/login',
  googleConnectRedirectUrl: 'https://client.cm.dev.kube.vsh-labs.cz/external-auth/google/connect',
  googleLoginRedirectUrl: 'https://client.cm.dev.kube.vsh-labs.cz/external-auth/google/login',
  stripePublishableKey: 'pk_test_51PHQflP3qvSvTb9oK1oljpEZ3020fGeQyMnY9rivkCBy0pA0h3CizqaWoezCP11mlRIVT2wt0TN0S0BIHbOkVsHk00u6c72V8X',
  appleIdConnectRedirectUrl: 'https://client.cm.dev.kube.vsh-labs.cz/external-auth/apple/connect',
  appleIdLoginRedirectUrl: 'https://client.cm.dev.kube.vsh-labs.cz/external-auth/apple/login'
};
